<template>
  <div>
    <el-row
      type="flex"
      justify="left"
      align="middle"
      class="top-search"
    >
      <el-radio-group
        v-model="videoStatus"
        class="radio-switch"
        size="mini"
      >
        <el-radio-button v-for="item in videoStatusList" :key="item.id" :label="item.id">
          {{ item.name }}
        </el-radio-button>
      </el-radio-group>
    </el-row>
    <div style="margin-top: 12px;">
      <WaitSubmitVideoList v-if="videoStatus === 0" />
      <UnSubmitVideoList v-if="videoStatus === 1" />
      <SubmitVideoList v-if="videoStatus === 2" />
      <PayedVideoList v-if="videoStatus === 3" />
    </div>
  </div>
</template>
<script>
import WaitSubmitVideoList from './components/WaitSubmitVideoList.vue'
import UnSubmitVideoList from './components/UnSubmitVideoList.vue'
import SubmitVideoList from './components/SubmitVideoList.vue'
import PayedVideoList from './components/PayedVideoList.vue'
export default {
  name: 'VideoEntrustList',
  components: {
    WaitSubmitVideoList,
    UnSubmitVideoList,
    SubmitVideoList,
    PayedVideoList
  },
  data() {
    return {
      videoStatus: 0,
      videoStatusList: [
        {
          id: 0,
          name: '待审核'
        },
        {
          id: 1,
          name: '未采购'
        },
        {
          id: 2,
          name: '审核成功待确认'
        },
        {
          id: 3,
          name: '已购买'
        }
      ]
    }
  }
}
</script>
<style lang='scss' scoped>
.radio-switch {
  ::v-deep {
    .el-radio-button .el-radio-button__inner {
      margin-right: 10px;
      border: none;
      background: #fafafa;
    }
    .el-radio-button .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background: rgba(61, 97, 227, 0.12);
    }
  }
}
</style>
