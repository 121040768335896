<template>
  <div>
    <div class="nav-wrapper">
      <div v-if="showBatchOperation" class="left">
        <ReportEntry v-slot="{ reportList, checkReport }" :push-location="7">
          <el-button
            v-if="reportList.length"
            plain
            type="primary"
            @click="onGetDataReport(checkReport)"
          >
            查看数据报告
          </el-button>
        </ReportEntry>
        <el-button
          class="reset-btn"
          type="primary"
          plain
          @click="onEndEntrust"
        >
          批量结束委托
        </el-button>
      </div>
      <div v-if="!showBatchOperation" class="left">
        <el-input
          v-model="searchParams.title"
          v-debounce="[onSearch, 'input']"
          class="input"
          clearable
          prefix-icon="el-icon-search"
          placeholder="请输入调研名称"
          @clear="onSearch"
          @keydown.enter.native="onSearch"
        />
        <el-select
          v-model="searchParams.reviewStatus"
          class="select"
          placeholder="所有状态"
          clearable
          @change="onSearch"
        >
          <el-option
            v-for="item in statusTypeList"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          />
        </el-select>
        <el-select
          v-model="searchParams.isQueryChild"
          class="select"
          placeholder="所有创建者"
          clearable
          @change="onSearch"
        >
          <el-option
            v-for="item in createrList"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          />
        </el-select>
        <el-select
          v-model="searchParams.tagId"
          class="select"
          clearable
          collapse-tags
          placeholder="所有产品"
          @change="onSearch"
        >
          <template v-for="item in tagList">
            <el-option
              :key="item.tagId"
              :value="item.tagId"
              :label="item.tagName"
            />
          </template>
        </el-select>
        <el-date-picker
          :value="[searchParams.startAt, searchParams.endAt]"
          :disabled="isExport"
          type="daterange"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="—"
          format="yyyy年MM月dd日"
          unlink-panels
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          :editable="false"
          @change="onChangeDate"
        />
        &nbsp;
        <el-radio-group v-model="activeRadio" @change="onChangeRadio">
          <el-radio-button
            v-for="item in radioList"
            :key="item.key"
            :label="item.key"
          >
            {{ item.name }}
          </el-radio-button>
        </el-radio-group>
      </div>
      <div class="right">
        <el-row class="action-fix">
          <el-button
            class="reset-btn"
            type="primary"
            plain
            @click="onBatchOperation"
          >
            {{ !showBatchOperation ? '批量操作' : '取消批量操作' }}
          </el-button>
          <el-button
            v-if="!isMobile"
            class="reset-btn"
            type="primary"
            icon="el-icon-plus"
            @click="onExport"
          >
            下载数据
          </el-button>
        </el-row>
      </div>
    </div>
    <el-table
      v-loading="loading"
      :data="academicEntrustList"
      stripe
      style="width: 100%;"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="showBatchOperation"
        type="selection"
        width="55"
        align="center"
        :selectable="row => row.questionnaireSubType "
      />
      <el-table-column
        label="调研名称"
        width="200"
        prop="title"
        fixed
        show-overflow-tooltip
      />
      <el-table-column label="活动场景" align="center" prop="sceneType">
        <template slot-scope="scope">
          <span>{{ sceneTypeStr(scope.row.sceneType, scope.row.questionnaireSubType) }}</span>
        </template>
      </el-table-column>
      <!-- 产品 -->
      <el-table-column label="产品" align="center" prop="tagName" />
      <!-- 总问卷数 -->
      <el-table-column label="总问卷数" align="center" prop="taskNum" />
      <!-- 已回收 -->
      <el-table-column label="已回收" align="center" prop="finishNum" />
      <!-- 未开始 -->
      <el-table-column label="未开始" align="center" prop="waitingStartNum" />
      <!-- 执行中 -->
      <el-table-column label="执行中" align="center" prop="processingNum" />
      <!-- 已失败 -->
      <el-table-column label="已失败" align="center" prop="failedNum" />
      <!-- 状态 -->
      <el-table-column label="状态" align="center" prop="servicePackageStatus">
        <template slot-scope="scope">
          <span
            v-if="scope.row.servicePackageStatus === 1"
            class="color-yellow"
          >审核中</span>
          <span
            v-if="scope.row.servicePackageStatus === 2"
            class="color-green"
          >进行中</span>
          <el-tooltip
            v-if="scope.row.servicePackageStatus === 3"
            class="item"
            effect="light"
            :content="'失败原因：' + scope.row.remark"
            placement="top-start"
          >
            <span class="color-red">审核失败</span>
          </el-tooltip>
          <span v-if="scope.row.servicePackageStatus === 4">已结束</span>
          <span
            v-if="scope.row.servicePackageStatus === 5"
            class="color-yellow"
          >审核成功待支付</span>
          <el-tooltip
            v-if="scope.row.servicePackageStatus === 6"
            class="item"
            effect="light"
            :content="'失败原因：' + scope.row.remark"
            placement="top-start"
          >
            <span class="color-red">委托失败</span>
          </el-tooltip>
          <span v-if="scope.row.servicePackageStatus === 7">已取消</span>
        </template>
      </el-table-column>
      <!-- 创建者 -->
      <el-table-column
        label="创建者"
        align="center"
        width="200"
        prop="orgName"
        show-overflow-tooltip
      />
      <!-- 创建时间 -->
      <el-table-column
        label="创建时间"
        align="center"
        width="150"
        prop="createdAt"
      />
      <!-- 操作 -->
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button
            v-if="
              scope.row.servicePackageStatus === 1 ||
                scope.row.servicePackageStatus === 2 ||
                scope.row.servicePackageStatus === 4
            "
            type="text"
            size="small"
            @click="onOperate(scope.row, 'detail')"
          >
            查看
          </el-button>
          <el-button
            v-if="scope.row.servicePackageStatus === 1"
            type="text"
            size="small"
            @click="onOperate(scope.row, 'cancel')"
          >
            取消申请
          </el-button>
          <el-button
            v-if="
              scope.row.servicePackageStatus === 3 ||
                scope.row.servicePackageStatus === 7
            "
            type="text"
            size="small"
            style="color: #e8312f;"
            @click="onOperate(scope.row, 'delete')"
          >
            删除
          </el-button>
          <el-button
            v-if="scope.row.servicePackageStatus === 2 && scope.row.questionnaireSubType !== 1"
            type="text"
            size="small"
            style="color: #e8312f;"
            @click="onOperate(scope.row, 'end')"
          >
            结束委托
          </el-button>
          <el-button
            v-if="
              (scope.row.servicePackageStatus === 3 ||
                scope.row.servicePackageStatus === 7) &&
                scope.row.canReDoDelegation
            "
            type="text"
            size="small"
            @click="onOperate(scope.row, 'restart')"
          >
            重新发起
          </el-button>
          <el-button
            v-if="
              scope.row.servicePackageStatus === 5 ||
                scope.row.servicePackageStatus === 6
            "
            type="text"
            size="small"
            @click="onOperate(scope.row, 'pay')"
          >
            重新发起
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 20px;"
      class="pagination"
      :page-size="pagination.pageSize"
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-sizes="pagination.sizes"
      :layout="pagination.layout"
      :small="pagination.small"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />
  </div>
</template>

<script>
import { isMobile } from '@/utils/util'
import paginationMixin from '@/mixins/pagination'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'AcademicEntrustList',
  mixins: [paginationMixin],
  data() {
    return {
      loading: false,
      activeRadio: 'three_month',
      radioList: [
        { name: '近一周', key: 'week' },
        { name: '近一月', key: 'month' },
        { name: '近三月', key: 'three_month' }
      ],
      showBatchOperation: false,
      searchParams: {
        title: '',
        reviewStatus: '',
        isQueryChild: false,
        tagId: '',
        startAt: `${this.$moment()
          .add(-3, 'month')
          .format('YYYY-MM-DD HH:mm:ss')}`,
        endAt: `${this.$moment().format('YYYY-MM-DD HH:mm:ss')}`
      },
      pagination: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        sizes: [10, 20, 50, 100],
        layout: 'total, sizes, prev, pager, next',
        small: true
      },
      statusTypeList: [
        // {
        //   id: 0,
        //   label: '待审核'
        // },
        {
          id: 1,
          label: '审核中'
        },
        {
          id: 2,
          label: '进行中'
        },
        {
          id: 3,
          label: '审核失败'
        },
        {
          id: 4,
          label: '已结束'
        },
        // {
        //   id: 5,
        //   label: '审核成功待支付'
        // },
        {
          id: 6,
          label: '委托失败'
        },
        {
          id: 7,
          label: '已取消'
        }
      ],
      createrList: [
        {
          id: false,
          label: '我创建的'
        },
        {
          id: true,
          label: '其他组织'
        }
      ],
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.searchParams.startAt = `${this.$moment(minDate).format(
            'YYYY-MM-DD'
          )} 00:00:00`
          this.searchParams.endAt = `${this.$moment(maxDate).format(
            'YYYY-MM-DD'
          )} 23:59:59`
        }
      },
      academicEntrustList: [],
      tagList: [], // 标签列表
      multipleSelection: {},
      isExport: false,
      isMobile: isMobile(),
      entrustId: ''
    }
  },
  computed: {
    ...mapGetters(['tagGroupList']),
    sceneTypeStr() {
      return (type, subType) => {
        const map = {
          1: '线上科室会',
          5: '定向直播',
          6: '多人会议',
          12: '医学播客',
          4: subType === 1 ? '问卷调研' : '病例互动'
        }
        return map[type] || '—'
      }
    }
  },
  mounted() {
    this.getDataList()
    this.initTag()
  },
  methods: {
    ...mapActions(['getTagGroup']),
    initTag() {
      this.getTagGroup().then(() => {
        this.tagList = []
        // 只展示产品标签
        this.tagGroupList.forEach(item => {
          if (item.groupCode === 'PRODUCT_TAG_GROUP') {
            this.getTagList(item.id)
          }
        })
      })
    },
    async getTagList(tagGroupId) {
      try {
        const params = { tagGroupId }
        const { data } = await this.$axios.get(this.$API.tagList, { params })
        this.tagList = [...this.tagList, ...data]
      } catch (error) {
        console.log(error)
      }
    },
    onChangeRadio(val) {
      this.activeRadio = val
      switch (this.activeRadio) {
        case 'week':
          this.searchParams.startAt = this.$moment()
            .add(-1, 'weeks')
            .format('YYYY-MM-DD HH:mm:ss')
          this.searchParams.endAt = this.$moment()
            .add(0, 'days')
            .format('YYYY-MM-DD HH:mm:ss')
          break
        case 'month':
          this.searchParams.startAt = this.$moment()
            .add(-1, 'months')
            .format('YYYY-MM-DD HH:mm:ss')
          this.searchParams.endAt = this.$moment()
            .add(0, 'days')
            .format('YYYY-MM-DD HH:mm:ss')
          break
        case 'three_month':
          this.searchParams.startAt = this.$moment()
            .add(-3, 'months')
            .format('YYYY-MM-DD HH:mm:ss')
          this.searchParams.endAt = this.$moment()
            .add(0, 'days')
            .format('YYYY-MM-DD HH:mm:ss')
          break
      }
      this.pagination.pageNo = 1
      this.getDataList()
    },
    // 学术调研委托服务
    getDataList() {
      if (!this.$moment(this.searchParams.startAt).isValid())
        return this.$message.error('请选择开始日期')
      if (!this.$moment(this.searchParams.endAt).isValid())
        return this.$message.error('请选择结束日期')
      this.loading = true
      const params = {
        ...this.searchParams,
        startAt: `${this.$moment(this.searchParams.startAt).format(
          'YYYY-MM-DD'
        )} 00:00:00`,
        endAt: `${this.$moment(this.searchParams.endAt).format(
          'YYYY-MM-DD'
        )} 23:59:59`,
        pageNo: this.pagination.pageNo,
        pageSize: this.pagination.pageSize
      }
      this.$axios
        .get(this.$API.getAcademicEntrustList, { params })
        .then(res => {
          this.loading = false
          this.pagination.total = res.data.totalCount
          this.academicEntrustList = res.data.data
        })
        .catch(rea => {
          this.loading = false
          this.$message.error(rea.message)
        })
    },
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    },
    onChangeDate(val) {
      if (val === null) {
        this.searchParams.startAt = this.$moment(
          this.searchParams.startAt
        ).format('YYYY-MM-DD HH:mm:ss')
        this.searchParams.endAt = this.$moment(this.searchParams.endAt).format(
          'YYYY-MM-DD HH:mm:ss'
        )
      }
      this.activeRadio = ''
      this.onSearch()
    },
    onExport() {
      if (!this.$moment(this.searchParams.startAt).isValid())
        return this.$message.error('请选择开始日期')
      if (!this.$moment(this.searchParams.endAt).isValid())
        return this.$message.error('请选择结束日期')
      if (!this.academicEntrustList.length)
        return this.$message.error('暂无需要导出的数据')
      if (this.isExport) return
      this.isExport = true
      this.searchParams.startAt = `${this.$moment(
        this.searchParams.startAt
      ).format('YYYY-MM-DD')} 00:00:00`
      this.searchParams.endAt = `${this.$moment(this.searchParams.endAt).format(
        'YYYY-MM-DD'
      )} 23:59:59`
      this.$axios
        .get(this.$API.exportAcademicEntrustList, { params: this.searchParams })
        .then(
          res => {
            this.isExport = false
            this.$message.success(res.data)
          },
          rea => {
            this.$message.error(rea.message)
          }
        )
        .catch(() => {
          this.isExport = false
        })
    },
    onOperate(row, type) {
      switch (type) {
        case 'cancel':
          this.$confirm(
            '学术调研委托审核中，是否取消本次委托，取消后，将会同步取消背后的学术任务',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }
          ).then(() => {
            this.$axios
              .get(this.$API.cancelAcademicEntrust, {
                params: { delegationId: row.id }
              })
              .then(
                () => {
                  this.$message.success('取消成功')
                  this.getDataList()
                },
                rea => {
                  this.$message.error(rea.message)
                }
              )
          })
          break
        case 'delete':
          this.$confirm('请确认是否删除本次学术调研委托任', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.endOrCancelEntrust([row.id], 0)
          })
          break
        case 'end':
          this.$confirm(
            `《${row.title}》学术调研委托正在进行中，请确认是否需要结束本次委托任务，本次委托调研共${row.taskNum}份，已回收${row.finishNum}份，可撤回${row.waitingStartNum}份。`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }
          ).then(() => {
            this.endOrCancelEntrust([row.id], 1)
          })

          break
        case 'restart':
          this.$confirm('请确认是否需要重新发起本次学术调研委托任务', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (row.sceneType === 4 && row.questionnaireSubType === 1) {
              this.$router.push({ name: 'CreatePureSurvey', query: {questionnaireId: row.sceneId} })

            } else {
              const sceneType = this.sceneType(row.sceneType)
              this.$router.push(
              `/academic/scene/${sceneType}/rule/${row.sceneId}?disabledBack=false`
              )
            }
          })
          break
        case 'pay':
          this.$confirm('请确认是否需要重新发起本次学术调研委托任务', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$axios
              .get(this.$API.restartAcademicEntrust, {
                params: { delegationId: row.id }
              })
              .then(
                () => {
                  this.$message.success('重新发起成功')
                  this.getDataList()
                },
                rea => {
                  this.$message.error(rea.message)
                }
              )
          })
          break
        case 'detail':
          if (row.sceneType === 4 && row.questionnaireSubType === 1) {
            this.$router.push({
              name: 'DelegationDetail',
              params: {
                id: row.id
              }
            }
            )
          } else {
            this.$router.push(
            `/entrust/detail/${row.id}?showEnd=${row.servicePackageStatus ===
              2}`
            )
          }
          break
      }
    },
    sceneType(type) {
      switch (type) {
        case 1:
          return 'meeting'
        case 4:
          return 'questionnaire'
        case 5:
          return 'live'
        case 6:
          return 'webinar'
        case 8:
          return 'academicVisit'
        case 7:
          return 'training'
        case 12:
          return 'MEDICAL_PODCAST'
        default:
          return 1
      }
    },
    onBatchOperation() {
      this.showBatchOperation = !this.showBatchOperation
    },
    onEndEntrust() {
      if (!this.multipleSelection.length)
        return this.$message.error('请选择需要结束的委托')
      this.$confirm(
        '请确认是否结束选中的所有委托任务，一经确定所有委托任务将会被撤回',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        const id = []
        this.multipleSelection.map(item => {
          if (item.servicePackageStatus === 2) {
            id.push(item.id)
          }
        })
        if (id.length === 0) {
          this.$message.error('没有委托任务需要结束')
          return false
        }
        this.endOrCancelEntrust(id, 1)
      })
    },
    onGetDataReport(checkReport) {
      if (!this.multipleSelection.length) {
        this.$message.error('请选择委托')
        return false
      }
      const id = this.multipleSelection.map(item => item.id).join(',')
      checkReport({ delegateId: id, showTime: false })
    },
    endOrCancelEntrust(id, type) {
      this.$axios
        .post(this.$API.batchOperateEntrust, { delegationIdList: id, type })
        .then(
          () => {
            this.$message.success(`${type === 1 ? '结束' : '删除'}成功`)
            this.multipleSelection = []
            // 延迟
            setTimeout(() => {
              this.getDataList()
            }, 1000)
          },
          rea => {
            this.$message.error(rea.message)
          }
        )
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    }
  }
}
</script>
<style lang="scss" scoped>
.action-fix {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
}
.reset-btn {
  margin-left: 10px;
}
.nav-wrapper {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    display: block;
  }
  .left {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .select {
      width: 120px;
      ::v-deep .el-select__tags {
        > span {
          width: 100%;
          display: grid;
          grid-template-columns: auto auto;
          > span {
            border: none;
            overflow: hidden;
            .el-tag__close.el-icon-close {
              background-color: transparent;
              &:hover {
                background-color: #c0c4cc;
                color: #909399;
              }
            }
          }
        }
      }
    }
    .input {
      width: 200px;
      ::v-deep .el-input__inner {
        background-color: #fafafa;
        border-radius: 20px;
      }
    }
  }
  .right {
    flex-shrink: 0;
    display: flex;
    align-items: flex-start;
    gap: 9px;
  }
}
</style>
