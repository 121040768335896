<template>
  <div>
    <el-row type="flex" justify="space-between" class="nav-wrapper">
      <div class="left">
        <el-input
          v-model="searchParams.liveTitle"
          v-debounce="[onSearch, 'input']"
          class="input"
          clearable
          prefix-icon="el-icon-search"
          placeholder="请输入直播/会议名称"
          @clear="onSearch"
          @keydown.enter.native="onSearch"
        />
        <el-select
          v-model="searchParams.isQueryChild"
          class="select"
          placeholder="所有创建者"
          clearable
          @change="onSearch"
        >
          <el-option
            v-for="item in createrList"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          />
        </el-select>
        <el-date-picker
          :value="[searchParams.startAt, searchParams.endAt]"
          :disabled="isExport"
          type="daterange"
          range-separator="—"
          :default-time="['00:00:00', '23:59:59']"
          format="yyyy年MM月dd日"
          unlink-panels
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          :editable="false"
          @change="onChangeDate"
        />
        <el-radio-group v-model="activeRadio" @change="onChangeRadio">
          <el-radio-button v-for="item in radioList" :key="item.key" :label="item.key">
            {{ item.name }}
          </el-radio-button>
        </el-radio-group>
      </div>
      <div class="right">
        <el-row class="action-fix">
          <el-button
            v-if="!isMobile && $auth('acadSaas.dService.vBuy.exportData')"
            class="reset-btn"
            type="primary"
            icon="el-icon-plus"
            @click="onExport"
          >
            下载数据
          </el-button>
        </el-row>
      </div>
    </el-row>
    <el-table
      v-loading="loading"
      :data="dataList"
      stripe
      style="width: 100%;"
    >
      <el-table-column
        label="直播/会议名称"
        prop="title"
        show-overflow-tooltip
        width="150"
        fixed
      />
      <el-table-column
        label="类型"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.sceneType === 5">直播</span>
          <span v-else-if="scope.row.sceneType === 6">会议</span>
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        align="center"
        prop="status"
      >
        <span>采购成功</span>
      </el-table-column>
      <el-table-column
        label="采购时间"
        align="center"
        prop="paidTime"
      />
      <el-table-column
        label="审核通过时间"
        align="center"
        prop="reviewTime"
      />
      <el-table-column
        label="创建者"
        align="center"
        prop="orgName"
      />
      <el-table-column
        label="操作"
        align="center"
        :fixed="!isMobile ? 'right' : false"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="onOperate(scope.row.replayVideoId)"
          >
            下载视频
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 20px;"
      class="pagination"
      :page-size="pagination.pageSize"
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-sizes="pagination.sizes"
      :layout="pagination.layout"
      :small="pagination.small"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />
  </div>
</template>

<script>
import { saveAs } from 'file-saver'
import paginationMixin from '@/mixins/pagination'
import { isMobile } from '@/utils/util.js'
export default {
  name: 'SubmitVideoList',
  mixins: [paginationMixin],
  data() {
    return {
      activeRadio: 'three_month',
      radioList: [
        { name: '近一周', key: 'week' },
        { name: '近一月', key: 'month' },
        { name: '近三月', key: 'three_month' }
      ],
      searchParams: {
        liveTitle: '',
        isQueryChild: false,
        startAt: `${this.$moment()
          .add(-3, 'month')
          .format('YYYY-MM-DD HH:mm:ss')}`,
        endAt: `${this.$moment().format('YYYY-MM-DD HH:mm:ss')}`
      },
      pagination: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        sizes: [10, 20, 50, 100],
        layout: 'total, sizes, prev, pager, next',
        small: true
      },
      createrList: [
        {
          id: false,
          label: '我创建的'
        },
        {
          id: true,
          label: '其他组织'
        }
      ],
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.searchParams.startAt = `${this.$moment(minDate).format('YYYY-MM-DD')} 00:00:00`
          this.searchParams.endAt = `${this.$moment(maxDate).format('YYYY-MM-DD')} 23:59:59`
        }
      },
      dataList: [],
      isExport: false,
      isMobile: isMobile()
    }
  },
  computed: {
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    onChangeRadio(val) {
      this.activeRadio = val
      switch (this.activeRadio) {
        case 'week':
          this.searchParams.startAt = this.$moment()
            .add(-1, 'weeks')
            .format('YYYY-MM-DD HH:mm:ss')
          this.searchParams.endAt = this.$moment()
            .add(0, 'days')
            .format('YYYY-MM-DD HH:mm:ss')
          break
        case 'month':
          this.searchParams.startAt = this.$moment()
            .add(-1, 'months')
            .format('YYYY-MM-DD HH:mm:ss')
          this.searchParams.endAt = this.$moment()
            .add(0, 'days')
            .format('YYYY-MM-DD HH:mm:ss')
          break
        case 'three_month':
          this.searchParams.startAt = this.$moment()
            .add(-3, 'months')
            .format('YYYY-MM-DD HH:mm:ss')
          this.searchParams.endAt = this.$moment()
            .add(0, 'days')
            .format('YYYY-MM-DD HH:mm:ss')
          break
      }
      this.pagination.pageNo = 1
      this.getDataList()
    },
    // 学术调研委托服务
    getDataList() {
      if (!this.$moment(this.searchParams.startAt).isValid()) return this.$message.error('请选择开始日期')
      if (!this.$moment(this.searchParams.endAt).isValid()) return this.$message.error('请选择结束日期')
      this.loading = true
      const params = {
        ...this.searchParams,
        pageNo: this.pagination.pageNo,
        pageSize: this.pagination.pageSize,
        startAt: `${this.$moment(this.searchParams.startAt).format('YYYY-MM-DD')} 00:00:00`,
        endAt: `${this.$moment(this.searchParams.endAt).format('YYYY-MM-DD')} 23:59:59`
      }
      this.$axios.get(this.$API.getPaidLiveList, { params }).then(res => {
        this.loading = false
        this.pagination.total = res.data.totalCount
        this.dataList = res.data.data
      }).catch(rea => {
        this.loading = false
        this.$message.error(rea.message)
      })
    },
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    },
    onChangeDate(val) {
      if (val === null) {
        this.searchParams.startAt = `${this.$moment(this.searchParams.startAt).format('YYYY-MM-DD')} 00:00:00`
        this.searchParams.endAt =  `${this.$moment(this.searchParams.endAt).format('YYYY-MM-DD')} 23:59:59`
      }
      this.activeRadio = ''
      this.onSearch()
    },
    onExport() {
      if (!this.$moment(this.searchParams.startAt).isValid()) return this.$message.error('请选择开始日期')
      if (!this.$moment(this.searchParams.endAt).isValid()) return this.$message.error('请选择结束日期')
      if (!this.dataList.length) return this.$message.error('暂无需要导出的数据')
      if (this.isExport) return
      this.isExport = true
      this.searchParams.startAt = `${this.$moment(this.searchParams.startAt).format('YYYY-MM-DD')} 00:00:00`
      this.searchParams.endAt = `${this.$moment(this.searchParams.endAt).format('YYYY-MM-DD')} 23:59:59`
      this.$axios.get(this.$API.exportPaidLiveList, { params: this.searchParams }).then(res => {
        this.isExport = false
        this.$message.success(res.data)
      }, rea => {
        this.$message.error(rea.message)
      }).catch(() => {
        this.isExport = false
      })
    },
    onOperate(videoUrl) {
      saveAs(videoUrl)
      this.$message.success('操作成功，视频下载中，请耐心等待')
    }
  }
}
</script>
<style lang="scss" scoped>
.action-fix {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
}
.reset-btn {
  margin-left: 10px;
}
.nav-wrapper {
  .left {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .select {
      width: 120px;
      ::v-deep .el-select__tags {
        >span {
          width: 100%;
          display: grid;
          grid-template-columns: auto auto;
          >span {
            border: none;
            overflow: hidden;
            .el-tag__close.el-icon-close {
              background-color: transparent;
              &:hover {
                background-color: #c0c4cc;
                color: #909399;
              }
            }
          }
        }
      }
    }
    .input {
      width: 200px;
      ::v-deep .el-input__inner {
        background-color: #fafafa;
        border-radius: 20px;
      }
    }
  }
  .right {
    flex-shrink: 0;
    display: flex;
    align-items: flex-start;
    gap: 9px;
  }
}
</style>
