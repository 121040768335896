<template>
  <div class="contaniner">
    <page-main class="customize-page-main">
      <el-tabs v-model="activeTab">
        <el-tab-pane
          v-for="item in tabList"
          :key="item.key"
          :label="item.name"
          :name="item.key"
        >
          <component :is="item.componentName" />
        </el-tab-pane>
      </el-tabs>
    </page-main>
  </div>
</template>

<script>
import VideoEntrustList from './VideoEntrustList.vue'
import AcademicEntrustList from './AcademicEntrustList.vue'
export default {
  name: 'EntrustList',
  components: {
    VideoEntrustList,
    AcademicEntrustList
  },
  data() {
    return {
      activeTab: 'academic',
      tabList: [
        {
          name: '学术调研委托服务',
          key: 'academic',
          auth: 'acadSaas.dService.aResearch',
          componentName: 'AcademicEntrustList'
        },
        {
          name: '视频委托采购服务',
          key: 'video',
          auth: 'acadSaas.dService.vPurServ',
          componentName: 'VideoEntrustList'
        }
      ].filter(item => item.auth ? this.$auth(item.auth) : true)
    }
  },
  mounted() {
    this.activeTab = this.tabList[0]?.key
  }
}
</script>
