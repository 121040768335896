<!--
 * @Author: your name
 * @Date: 2020-12-14 15:41:00
 * @LastEditTime: 2022-01-12 15:52:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/bizComponents/AccountLivePayModal.vue
-->
<template>
  <el-dialog title="支付" width="35%" :visible.sync="visible" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" destroy-on-close>
    <el-row type="flex" justify="space-between" class="desc-text">
      <span v-if="item.sceneType === 5">直播频道：</span>
      <span v-if="item.sceneType === 6">多人会议：</span>
      <span class="flex-box align-right">《{{ item.title || '—' }}》</span>
    </el-row>
    <el-row type="flex" justify="space-between" align="middle" class="desc-text">
      <span>播客：</span>
      <span class="flex-box align-right">{{ (item.doctor && item.doctor.name) || '—' }}</span>
    </el-row>
    <el-row type="flex" justify="space-between" align="middle" class="desc-text">
      <span>开始时间：</span>
      <span class="flex-box align-right">{{ item.startAt || '—' }}</span>
    </el-row>
    <el-row type="flex" justify="space-between" align="middle" class="desc-text">
      <span>结束时间：</span>
      <span class="flex-box align-right">{{ item.finishAt || '—' }}</span>
    </el-row>
    <el-divider class="w-h"></el-divider>
    <el-row type="flex" justify="space-between" align="middle" class="desc-text">
      <span>当前直播/会议服务费余额：</span>
      <span class="flex-box align-right">{{ accountLiveSummaryData.disposableAmount || 0 }}</span>
    </el-row>
    <el-row type="flex" justify="space-between" align="middle" class="desc-text">
      <span v-if="item.sceneType === 5">支付直播服务费：</span>
      <span v-if="item.sceneType === 6">支付会议服务费：</span>
      <span class="flex-box align-right">{{ item.fee || 0 }}</span>
    </el-row>
    <div class="color-assist m-t">备注：提交支付申请后，内容需要平台审核通过后，即可完成支付。</div>
    <el-divider class="w-h"></el-divider>
    <div class="color-red">{{ errorMessage }}</div>
    <el-row slot="footer" type="flex" justify="center">
      <el-button plain @click="onClose">取消</el-button>
      <el-button type="primary" @click="onSave" :loading="loading" :disabled="!!errorMessage">确认</el-button>
    </el-row>
  </el-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'AccountLivePayModal',
  data() {
    return {
      money: '',
      errorMessage: '',
      loading: false,
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    ...mapGetters(['accountLiveSummaryData']),
  },
  filters: {},
  methods: {
    ...mapActions(['onPayLiveAccount', 'getAccountLiveSummary']),
    onValid() {
      let valid = true
      if (!this.item.fee) {
        valid = false
        this.$message.error('暂无需要支付的直播服务费')
      }
      return valid
    },
    onSave() {
      if (this.loading) return
      this.loading = true
      let req = {
        liveId: this.item.liveId,
        doctorId: this.item.doctor.id,
      }
      this.onPayLiveAccount(req).then(
        (res) => {
          this.loading = false
          this.$message.success('支付成功')
          this.onClose()
        },
        (rea) => {
          this.loading = false
          if (rea.code === 1079) {
            this.errorMessage = '此医生身份认证出现问题，有较大风险，暂时无法使用部分功能，请提醒医生重新上传工作证明并完成实名认证后重试'
            return
          }
          this.$message.error(rea.message)
        }
      )
    },
    onClose() {
      this.$emit('onClose')
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.errorMessage = ''
        this.getAccountLiveSummary()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.certify-image {
  height: 160px;
  width: 160px;
  background-color: #f6f7fb;
  margin-top: 10px;
}
.desc-text {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #272e40;
}
.el-divider--horizontal {
  margin: 15px 0;
}
.m-t {
  margin-top: 40px;
}
</style>
