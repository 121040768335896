<template>
  <div class="look-train-data-box">
    <page-main title back>
      <el-row type="flex" justify="space-between" class="main">
        <el-row type="flex">
          <div class="entrust-cover">
            <img :src="coverUrl" alt="">
          </div>
          <div style="min-width: 150px;">
            <div class="font-bold">{{ entrustDetail.title }}</div>
            <div class="mgn-t20 col7784a1">
              <div class="mgn-b5">创建时间：{{ entrustDetail.createdAt }}</div>
              <div class="mgn-b5">产品标签：<span class="entrust-tag">{{ entrustDetail.tagName }}</span></div>
              <div class="col7784a1">调研备注：{{ entrustDetail.remark }}</div>
            </div>
          </div>
        </el-row>
        <el-row
          type="flex"
          align="middle"
          justify="space-between"
        >
          <div class="task-item">
            <div class="task-title">总问卷数（次）</div>
            <div class="task-num">{{ entrustDetail.taskNum || 0 }}</div>
          </div>
          <div class="task-item">
            <div class="task-title">未开始（次）</div>
            <div class="task-num">{{ entrustDetail.waitingStartNum || 0 }}</div>
          </div>
          <div class="task-item">
            <div class="task-title">执行中（次）</div>
            <div class="task-num">{{ entrustDetail.processingNum || 0 }}</div>
          </div>
          <div class="task-item">
            <div class="task-title">已回收（次）</div>
            <div class="task-num">{{ entrustDetail.finishNum || 0 }}</div>
          </div>
        </el-row>
      </el-row>
    </page-main>
    <page-main class="customize-page-main">
      <el-tabs v-model="activeTab">
        <el-tab-pane
          v-for="item in tabList"
          :key="item.key"
          :label="item.name"
          :name="item.key"
        />
      </el-tabs>
      <div v-if="activeTab === 'exam'" class="table-box">
        <div v-if="itemExamVO.answerType === 1" class="font-s16 mgn-t10">{{ `${'共' + examList.length + '题（答题时随机展示' + itemExamVO.randomExamCount + '题）'}` }}</div>
        <div v-else class="font-s16 mgn-t10">{{ `${'共' + examList.length + '题（答题时按顺序展示）'}` }}</div>
        <exam-item
          v-for="(item, index) in examList"
          :key="item.examId"
          :item="item"
          :index="index + 1"
        />
      </div>
    </page-main>
    <fixed-action-bar v-if="showEnd">
      <el-button type="danger" plain @click="onEndEntrust">结束服务</el-button>
    </fixed-action-bar>
  </div>
</template>
<script>
import ExamItem from '@/bizComponents/ExamItem'
export default {
  name: 'EntrustDetail',
  components: {
    ExamItem
  },
  data() {
    return {
      tabList: [{
        name: '委托内容',
        key: 'exam'
      }
      ],
      activeTab: 'exam',
      delegationId: '',
      entrustDetail: {},
      itemExamVO: {},
      showEnd: false,
      examList: []
    }
  },
  computed: {
    coverUrl() {
      return require('@/assets/academic/entrust.png')
    }
  },
  mounted() {
    this.delegationId = this.$route.params.id
    this.showEnd = this.$route.query.showEnd === 'true'
    this.init()
  },
  methods: {
    init() {
      this.$axios.get(this.$API.getEntrustDetail, {params: {delegationId: this.delegationId}}).then(res => {
        this.entrustDetail = res.data
        this.itemExamVO = res.data.itemExamVO || {}
        this.examList = this.itemExamVO.exams || []
      })
    },
    onEndEntrust() {
      this.$confirm('请确认是否结束此委托服务', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$API.batchOperateEntrust, { delegationIdList: [this.delegationId], type: 1 }).then(() => {
          this.$message.success('已结束服务')
          this.showEnd = false
          this.init()
        }, rea => {
          this.$message.error(rea.message)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.table-box {
  min-height: 400px;
}
.entrust-tag {
  color: #3d61e3;
  background: rgba(61, 97, 227, 0.12);
  border-radius: 16px;
  height: 20px;
  flex-shrink: 0;
  line-height: 12px;
  padding: 4px 10px;
  margin-right: 10px;
}
.entrust-cover {
  margin-right: 15px;
  width: 120px;
  height: 120px;
  img {
    border-radius: 16px;
    width: 100%;
    height: 100%;
  }
}
.task-item {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.task-title {
  color: #5a5a5a;
  margin-bottom: 10px;
}
.task-num {
  font-size: 30px;
}
.main {
  @media screen and (max-width: 786px) {
    flex-direction: column;
    .el-row {
      margin-bottom: 20px;
    }
  }
}
</style>
